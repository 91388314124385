// src/App.scss
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}

main {
  flex: 1;
}

.options button {
  margin: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #ccc;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options button:hover {
  background-color: #999;
}

.options .selected {
  background-color: #999;
}
