// src/components/Footer.scss
.Footer {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

a {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
