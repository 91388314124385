// src/views/Home.module.scss
.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.option {
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e0e0e0;
  }
}

.selected {
  background-color: #d0d0d0;
}

.textInput {
  width: 100%;
  max-width: 800px;
  min-width: 450px;
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  resize: vertical;
}

.output {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  min-width: 450px;
  font-size: 1rem;
  white-space: pre-wrap;
}


.descriptionSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;
}

.description {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  background-color: #f8f8f8;
  position: relative;
}

.selectedDescription {
  border: 2px solid #333;
  border-radius: 5px;
  padding: 0.5rem;
}

.description h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.description p {
  margin-bottom: 0;
}

.transformedText {
  width: 100%;
  word-wrap: break-word;
}

.copyButton {
  background-color: #333;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  margin-top: 1rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #444;
  }
}
