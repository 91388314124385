// src/views/About.module.scss
.About {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: left;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  ol, ul {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
}
