/* src/components/Headermodule.css */
.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 1rem;
}

.Header h1 {
  margin: 0;
}

.Header h1 a {
  color: #fff;
  text-decoration: none;
}

.Header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.Header nav ul li {
  margin-left: 1rem;
}

.Header nav ul li a {
  color: #fff;
  text-decoration: none;
}

.Header nav ul li a:hover {
  text-decoration: underline;
}